/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import Wufoo from 'react-wufoo-embed'
import { uniqueId } from 'lodash'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero, CodeRed } from '../components/elements'


const Contact = ({ data }) => {

  const page = data.prismic.contact

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={RichText.asText(page.title)} />
      <Section >
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">

            {page.body.map(({ fields, primary }) => (
              <Box key={uniqueId('contact_block_')} width={["full"]} sx={{
                backgroundColor: 'wrapperbox',
                padding: [4, 5],
                margin: 3
              }}>
                {RichText.render(primary.heading)}
                <Flex flexWrap="wrap" mx={-4}>
                  {fields.map(({ info }) => (
                    <Box key={uniqueId('content_block_')} width={['full', 'full', 1 / 2]} px={4}>{RichText.render(info)} </Box>
                  ))}
                </Flex>

              </Box>
            ))}


            <Box width={["full"]} sx={{
              backgroundColor: 'wrapperbox',
              padding: [4, 5],
              margin: 3
            }}>
              <h2>Contact Us</h2>
              <Wufoo header="hide" userName="mindmerge" formHash="z1ugmvgq12g4r76" />
            </Box>

            <CodeRed />
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Contact

export const query = graphql`
query ContactQuery {
  prismic {
    contact(lang: "en-us", uid: "contact") {
      content
      meta_description
      meta_title
      title
      body {
        ... on PRISMIC_ContactBodyInfo_block {
          type
          label
          primary {
            heading
          }
          fields {
            info
          }
        }
      }
    }
  }
}
`